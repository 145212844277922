<template>
    <div>
		<div v-if="CurrentActivity" style="position: absolute;top: -100px;left: 0;width: 290px;text-align: center;z-index: 3; color:#f0f1db;font-size:18px;" >
			<div>剩余抽奖券</div>
			<div style="font-size: 30px;font-weight:700;">{{CurrentActivity}}</div>
		</div>
		<div v-else style="position: absolute;top: -100px;left: 0;width: 290px;text-align: center;z-index: 3; color:#f0f1db;font-size:18px;" >
			<div>每次抽奖所需积分</div>
			<div style="font-size: 30px;font-weight:700;">{{FUsePoint}}</div>
		</div>
		<div class="box">
			<div class="groups groups1 animation-ease" :height="groupsHeight" @webkitTransitionEnd="endGame(1)">
				<div class="group-item" v-for="i in (round+1)" style="padding: 0;">					
					<div class="prize-item" v-for="item in prizesdata" style="list-style: none;display: flex;justify-content: center;align-items: center;flex-direction: column;">
						<img :src="item.fPicturePath" mode=""></img>
					</div>
				</div>
			</div>
			<div class="groups groups2 animation-ease" :height="groupsHeight" @webkitTransitionEnd="endGame(2)">
				<div class="group-item" v-for="i in (round+1)" style="padding: 0;">					
					<div class="prize-item" v-for="item in prizesdata" style="list-style: none;display: flex;justify-content: center;align-items: center;flex-direction: column;">
						<img :src="item.fPicturePath" mode=""></img>
					</div>
				</div> 
			</div>
			<div class="groups groups3 animation-ease" :height="groupsHeight" @webkitTransitionEnd="endGame(3)">
				<div class="group-item" v-for="i in (round+1)" style="padding: 0;">					
					<div class="prize-item" v-for="item in prizesdata" style="list-style: none;display: flex;justify-content: center;align-items: center;flex-direction: column;">
						<img :src="item.fPicturePath" mode=""></img>
					</div>
				</div>
			</div>
		</div>
		<div style="color: #fff;margin-left: 56px;text-shadow:5px 5px 6px #ee6907; margin-top: 10px;color: #f0f1db; text-align: center;font-size: 18px;font-weight: bold;cursor: pointer;"  @click="startClick">{{disClick?'抽奖中...':'点击开始'}}</div>
		<el-dialog
		  :visible.sync="centerDialogVisible"
		  width="30%"
		  top="35vh"
		  :modal="true"
		  :modal-append-to-body="false"
		  center>
		  <span style="text-align: center;display: block;">{{showtext}}</span>
		  <span slot="footer" class="dialog-footer">
		    <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
		    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
import $ from '@/assets/js/jq.js'
export default {
	props: { 
	  shopid: {  //店铺ID
		  type:String,
		  default:""
	  },
	},
	watch:{
		shopid(e,f){
			console.log('获取父组件的IDSSSSSS',e)
			this.spid = e
			this.getprizeslist()
		}
	},
	data(){
		return {
			disClick:false,//防止多次点击
			itemHeight:0,//每个奖品的高度
			groupsHeight:0,
			spid:"", //店铺ID
			//本次中奖的奖品ID
			priid:"",
			// 转几回合后停下
			round:0,
			//本次是否中奖
			ispris:false,
			//抽奖失败后的提示语，后端返回
			downtext:"",
			apiisok:false, // 接口是否调完 
			endGAMES:false,//动画是否跑完
			round:6,//转几回合后停下来
			showtext:"",//中奖信息
			centerDialogVisible:false, //中奖后弹出框，是否弹出
			luckindex:0,//抽奖次数
			prizes:[],//奖品列表
			prizesdata:[],//接收来自父页面传来的奖品列表数组，存到本页面 因为方法里面需要改
			randomlist:[0,0,0],//每次随机的三个数组存入 初始化是0，0，0，
			FUsePoint:0,
			changeprizes:[], //中奖数组
			fAwardPoolID: '', // 活动id
			CurrentActivity: 0, // 抽奖券数量
		}
	},
	mounted(){
		console.log('获取数据列表的ID',this.shopid)
		// 初始化给奖品列表赋值下标,用来区分 便于循环滚动
		
	},
	methods:{
		async getprizeslist(){
			console.log('dddddddddddddddddddddddddd',this.spid)
			// console.log('获取返回的数据列表',this.)
			let res = await this.ApiRequestPostNOMess('api/mall/ebactivity/luck-draw-award-pool/get-list-by-fshopID', {FShopID:this.spid})
			console.log('接口请求返回数据',res.obj)
			this.prizes = res.obj[0].fDetaillist
			this.FUsePoint =  res.obj[0].fUsePoint
			for(var i = 0;i< this.prizes.length;i++){
				this.prizes[i].showindex = i+1
			}	
			this.prizesdata = this.prizes
			this.fAwardPoolID = res.obj[0].fAwardPoolID
			this.getCurrentActivity()
		},
		
		// 获取 n-m 之间的随机整数  随机数  n开始数字   m结束数字
		randomnumber(n,m){
			if(!Number(n))
			    throw new Error(`${n} is not Number`)
			if(!Number(m))
			    throw new Error(`${m} is not Number`)
			return Math.round(Math.random() * (m - n) + n)
		},
		// 传入最小值和最大值,返回三个不一样的随机数 保证三个值不同
		randomUnequal(minlength,maxlength){
			let arr = []
			for(var i = 0;i<2;i++){
				arr.push(this.randomnumber(minlength,(maxlength)))
			}
			if(arr[0] == 2){
				arr.push(1);
			}else if(arr[0] == 1){
				arr.push(2);
			}else{
				arr.push(3);
			} 
			return arr 
		},
		// 获取奖券数量
		getCurrentActivity() {
			this.ApiRequestPostNOMess('api/mall/ebactivity/gift-record/get-current-activity',{
				fAwardPoolID:this.fAwardPoolID,
				fShopID: this.spid
			}).then(res => {
				console.log(res)
				this.CurrentActivity = res.obj
			})
		},
		// 点击开始抽奖
	   async startClick(){ 
			if (this.CurrentActivity) {
			   //开始抽奖
			   	// 防止连续点击
			   	if(this.disClick){
			   		return
			   	}
			   this.resetGame()
			   	// 请求本人是否中奖
			   	this.ApiRequestPostNOMess('api/mall/ebactivity/luck-draw-award-pool/get-user-luck-draw-shop',{
			   		fAppTypeID: this.fAppTypeID,
			   		fShopUnitID: this.shopid
			   	}).then(
			   		ress => {
						this.getCurrentActivity()
			   			if(JSON.parse(ress.obj).length >0){
			   				this.apiisok = true
			   				this.ispris = true
			   				//获取中奖的id
			   				// 在这个地方提前设置中奖的产品
			   				this.priid =  JSON.parse(ress.obj)[0].FAwardID
			   				this.changeprizes = JSON.parse(ress.obj)
			   				let arr = this.prizes;
			   				let index = 0;
			   				for(var i =0;i<arr.length;i++){
			   					if(arr[i].fAwardID == this.priid){
			   						index = i
			   					}
			   				}
			   				this.runGame(index,true)
			   			}else{
			   				let index = 0
			   				// 如果没有中奖
			   				this.apiisok = true
			   				this.ispris = false
			   				// this.downtext = rejs.error.message
			   				this.runGame(index,false)
			   			}
			   			
			   		},
			   		rejs => {
						this.getCurrentActivity()
			   			console.log(rejs,'1111111111112')
			   			if(rejs.error.details!=null && rejs.error.details.indexOf("结束")>-1){
			   				this.tipsInfo('亲，本次抽奖活动已结束')
			   			}else{ 
			   				let index = 0
			   				// 如果没有中奖
			   				this.apiisok = true
			   				this.ispris = false
			   				this.downtext = "抱歉，本次没有中奖"
			   				this.tipsInfo(rejs.error.message)
			   						
			   				// this.runGame(index,false)
			   			}
			   		}
			   	);
			} else {
			   // 获取当前客户在商铺有多少积分
			   this.ApiRequestPostNOMess('api/mall/ebbalance/customer-point/get-by-shop-unit-id',{
			   	id: this.shopid
			   }).then(
			   		res => {
			   			let blance = res.obj.fEndBalance
			   			if(this.FUsePoint > blance){
			   				this.tipsInfo('很抱歉，您的积分不足。')
			   				return;
			   			}else{
			   				//开始抽奖
			   				// 防止连续点击
			   				if(this.disClick){
			   					return
			   				}
			   			   this.resetGame()
			   				// 请求本人是否中奖
			   				this.ApiRequestPostNOMess('api/mall/ebactivity/luck-draw-award-pool/get-user-luck-draw-shop',{
			   					fAppTypeID: this.fAppTypeID,
			   					fShopUnitID: this.shopid
			   				}).then(
			   					ress => {
									this.getCurrentActivity()
			   						if(JSON.parse(ress.obj).length >0){
			   							this.apiisok = true
			   							this.ispris = true
			   							//获取中奖的id
			   							// 在这个地方提前设置中奖的产品
			   							this.priid =  JSON.parse(ress.obj)[0].FAwardID
			   							this.changeprizes = JSON.parse(ress.obj)
			   							let arr = this.prizes;
			   							let index = 0;
			   							for(var i =0;i<arr.length;i++){
			   								if(arr[i].fAwardID == this.priid){
			   									index = i
			   								}
			   							}
			   							this.runGame(index,true)
			   						}else{
			   							let index = 0
			   							// 如果没有中奖
			   							this.apiisok = true
			   							this.ispris = false
			   							// this.downtext = rejs.error.message
			   							this.runGame(index,false)
			   						}
			   					},
			   					rejs => {
									this.getCurrentActivity()
			   						console.log(rejs,'1111111111112')
			   						if(rejs.error.details!=null && rejs.error.details.indexOf("结束")>-1){
			   							this.tipsInfo('亲，本次抽奖活动已结束')
			   						}else{ 
			   							let index = 0
			   							// 如果没有中奖
			   							this.apiisok = true
			   							this.ispris = false
			   							this.downtext = "抱歉，本次没有中奖"
										this.tipsInfo(rejs.error.message)
			   							// this.runGame(index,false)
			   						}
			   					}
			   				);
			   			}
			   		},
			   		rej => {
			   			
			   		}
			   	);
			}
		},
		// 启动抽奖   index 
		async runGame(index,e){  //启动抽奖
			this.disClick = true;
			this.resetGame(); 
			if(this.prizes.length  < 8){
				this.round = 5
			}
			if(this.prizes.length <= 13 && this.prizes.length >= 8){
				this.round = 4
			}
			if(this.prizes.length <= 20 && this.prizes.length > 13){
				this.round = 3
			}
			if(this.prizes.length <= 30 && this.prizes.length > 21){
				this.round = 2
			}
			if(this.prizes.length <= 50 && this.prizes.length > 31){
				this.round = 1
			}
			if(this.prizes.length > 50){
				this.round = 0
			}
			// 如果中奖了
			if(e == true){ 
				this.itemHeight = $('.prize-item').outerHeight()
				console.log('初始化拿到高度',this.itemHeight) 
				$('.groups').css('height',this.itemHeight * this.prizes.length*(this.round+1))
				
				// 获取 每一个奖品高度
				var itemHeight = this.itemHeight;
				console.log('itemheight高度',$('.prize-item').outerHeight())
				
				// 获取转几圈一共的高度
				var groupsHeight = this.round*$('.group-item').height();
				
				//获取一共需要转的高度，加上需要中奖奖品的高度
				var pos = index*itemHeight + groupsHeight
				
				setTimeout(()=>{
					$('.groups1').addClass('animation-ease').css('transform','translate3d(0, -'+pos+'px, 0)')
				},200)
				setTimeout(()=>{
					$('.groups2').addClass('animation-ease').css('transform','translate3d(0, -'+pos+'px, 0)')
				},600)
				setTimeout(()=>{
					$('.groups3').addClass('animation-ease').css('transform','translate3d(0, -'+pos+'px, 0)')
				},800)
			}else{
				
				
			// 如果没中奖	
				console.log('随机的三个数字',this.randomlist);
				console.log('奖品列表',this.prizes);
				console.log('没中奖====================')
				this.itemHeight = $('.prize-item').outerHeight()
				
				$('.groups').css('height',this.itemHeight * this.prizes.length*(this.round+1))
				
				// 获取 每一个奖品高度
				var itemHeight = this.itemHeight;
				console.log('itemheight高度',$('.prize-item').outerHeight())
				
				// 获取转几圈一共的高度
				var groupsHeight = this.round*$('.group-item').height();
				console.error(this.prizes.length);
			 	var ress = await this.randomUnequal(1,(this.prizes.length)-1)
				console.error(ress);
				//存入随机数列表，在下次滚动时接着上面滚动, \暂时没写
				this.randomlist = ress;
				
				//获取一共需要转的高度，加上需要中奖奖品的高度
				var pos = ress[0]*itemHeight + groupsHeight
				
				//获取一共需要转的高度，加上需要中奖奖品的高度
				var pos1 = ress[1]*itemHeight + groupsHeight
				
				//获取一共需要转的高度，加上需要中奖奖品的高度
				var pos2 = ress[2]*itemHeight + groupsHeight
				
				setTimeout(()=>{
					$('.groups1').addClass('animation-ease').css('transform','translate3d(0, -'+pos+'px, 0)')
				},200)
				setTimeout(()=>{
					$('.groups2').addClass('animation-ease').css('transform','translate3d(0, -'+pos1+'px, 0)')
				},600)
				setTimeout(()=>{
					$('.groups3').addClass('animation-ease').css('transform','translate3d(0, -'+pos2+'px, 0)')
				},800)
			}
			

		},
        endGame(k){  //抽奖结束后的回调
		
			this.endGAMES = true;
			//抽奖结束&& 接口调用完毕后的回调
			if(k == 3){
				if(this.ispris){
					let changes =  this.changeprizes[0]
					// 恭喜您抽中“六等奖”（奖品: 积分奖励，积分：10）
					let tipContent =""
					if(changes.FAwardPoint > 0){
						tipContent = '恭喜您抽中'+changes.FPrizeGradeName+'（奖品：'+changes.FAwardName+"积分："+changes.FAwardPoint+"）"
					}else{
					    tipContent = '恭喜您抽中'+changes.FPrizeGradeName+'（奖品：'+changes.FAwardName+'）'
					}
					this.showtext = tipContent
					this.centerDialogVisible = true
				}else{
					this.showtext = "很抱歉，本次没有中奖"
					this.centerDialogVisible = true
				}
				this.$emit('back',{
					ispris:this.ispris,
					text:this.downtext,
					priid:this.priid
				})
				this.disClick = false;
			}
		},
		resetGame(){//重置状态
			$('.groups1').removeClass('animation-ease').css('transform','');
			$('.groups2').removeClass('animation-ease').css('transform','');
			$('.groups3').removeClass('animation-ease').css('transform','');
		}
	}
}
</script>



<style lang="scss" scoped>
.box{
	width:102%;
	height:50px;
	overflow: hidden;
	background: rgba(0,0,0,0);
	display: flex;
	margin-left: 31px;
	min-height: 100%;
	margin-bottom: 82px;
	margin-top: 32px;
	.animation-ease{
		transition-property:transform;
		transition-duration: 3s;
		transition-timing-function: ease;
	}
	.groups{
		float: left;
		width:100%;
		text-align: center;
		.prize-item{
			width:100%;
			height:50px;
			font-size:20rpx;
			
			img {
				width: 54px;
				height: 54px;
				border-radius: 5px;
			}
		}
	}
}
</style>