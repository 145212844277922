<template>
	<div>
		<!-- 没数据时 -->
		<div v-show="showImge">
			<!-- <img width="100%" src="../../assets/pointImg.png" /> -->
			<div class="page-box" style="position: relative;width: 1920px;height: 600px;; background-color:#f5fafe;" >
				<div style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;">
					<div>
						<img src="../assets/nogoods.png" width="100%">
						<div
							style="width: 103;font-size: 30px;line-height: 80px;font-weight: bold;color: #1144ab;text-align: center;letter-spacing: 5px;padding:0 10px;">
							活动正在筹划中...</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="!showImge"  class="page-box" style="position: relative;width: 1920px;background-color: rgb(122,194,236);">
			<div style=" width: 1920px;height: 600px; ">
				<img src="../assets/choujiangPC.png" alt="" style="position: absolute;  width: 1920px;">
				<div style="position: absolute;top: 318px;left: 570px;width: 226px;z-index: 3;">
					<choujiangdetail :shopid="shopID" @back='choujiangback' :FUsePoint="FUsePoint" ></choujiangdetail>
				</div>
				
				<div style="width: 1100px;px;height: 100%;margin: 0 auto;">
					<div style="position: relative;float: right;margin-top: 46px;width: 360px;height: 460px;background-color: #a63145;padding: 20px;border-radius: 25px;border: 3px #fcecae solid ;color: #FFF8CB;">
						<div class="almost-lottery__rule" style="padding-bottom: 20px;float: left;height: 450px;overflow: auto;width: 100%;">
						  <div class="rule-body" style="width: 100%;">
						    <div class="item" style="line-height: 26px;">
						      <div class="p">
						        <p>活动时间：</p>
						        <p>{{fBeginTime}} &nbsp;&nbsp;至 &nbsp;&nbsp; {{fEndTime}}</p>
						      </div>
						    </div>
							<div class="item item-rule">
							 <!-- <div class="number">2</div> -->
							  <div class="p">
							    <p>奖品内容如下：</p>
								<div class="prisbox">
									<div class="pris_item">
										<div class="pris_title">奖品等级</div>
										<div class="pris_content">奖品名称</div>
									</div>
									<div class="pris_item" v-for="(list, indexMain) in prizes" :key="indexMain">
										<div class="pris_title">{{list.fPrizeGradeName}}</div>
										<div class="pris_content">{{list.fAwardName}}</div>
									</div>
								</div>
							  </div>
							</div>
						    <template>
						     <div class="item"><div class="p">抽奖规则：</div></div>
							 
						     <div class="item" style="line-height: 26px;" v-for="(item, index) in rules" :key="index"><div class="p">{{item.fSort}}.{{item.fDescribe}}</div></div>
							</template>
						  </div>
						</div>
					</div>
				</div>
			    <div style=" position: absolute;top: 450px;left:12%;width:400px;height:100px;color: #FFF8CB;">
					<div class="forecastbox">
					 <vue-seamless-scroll
						    :data="dataArr"
						    class="seamless-warp"
						    :class-option="classOption"           
						    >
						    <div class="textbox" v-for="(i, j) in dataArr" :key="j">
						      用户{{ i.fCustomerName }}中奖{{i.fAwardName}}&nbsp;&nbsp;{{i.minute}}前
						    </div>         
					</vue-seamless-scroll>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import vueSeamlessScroll from "vue-seamless-scroll";
	import {
		mapActions
	} from 'vuex';
	import {
		mapGetters
	} from 'vuex';
	import choujiangdetail  from  '@/components/luckDraw/luckDraw.vue'
	export default {
		components: {
			choujiangdetail,
			 vueSeamlessScroll
		},
		data() {
			return {
				dataArr:[],
				prizes:[],//奖品列表
				shopID:"", // 店铺ID
				FUsePoint:0,//每次抽奖需要多少积分
				fBeginTime:"",
				fEndTime:"",
				rules:[],
				fAwardPoolID: '', // 活动id
				showImge: true,
			}
		},
		beforeCreate() {
			this.shopID = this.$route.query.shopId
		},
		mounted() {
			console.log('返回的商铺ID',this.$route.query.shopId)
			this.shopID = this.$route.query.shopId
			this.getprizess();
		},
		computed: {
		    // 滚动
		    classOption() {
		      return {
		        step: 0.2, // 数值越大速度滚动越快
		        limitMoveNum: 0, // 开始无缝滚动的数据量
		        hoverStop: true, // 是否开启鼠标悬停stop
		        direction: 1, // 0向下 1向上 2向左 3向右
		        openWatch: true, // 开启数据实时监控刷新dom
		        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)
		        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动)
		        waitTime: 3000, // 单步运动停止的时间(默认值1000ms)
		      };
		    },
		  },
		methods: {
			// 获取中奖名单
			getWinningList() {
				this.ApiRequestPostNOMess('api/mall/ebactivity/luck-draw-record/get-list-new', {
					fAwardPoolID: this.fAwardPoolID,
					skipCount:0,
					maxResultCount:10,
				}).then(res => {
					this.dataArr = res.obj.items
					this.dataArr.forEach(item => {
						let time3 = new Date().getTime() - new Date(item.fCreateTime).getTime()
						let minute = '1分钟'
						//计算出相差天数
						var days = Math.floor(time3 / (24 * 3600 * 1000))
						//计算出小时数
						var leave1 = time3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
						var hours = Math.floor(leave1 / (3600 * 1000))
						//计算相差分钟数
						var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
						var minutes = Math.floor(leave2 / (60 * 1000))
						if (days > 0) {
						    minute = days + "天" + hours + "小时 " + minutes + "分钟";
						} else if (hours > 0) {
						    minute = hours + "小时" + minutes + "分钟";
						} else if (minutes > 0) {
							console.log(minutes)
						    minute = minutes + "分钟";
						}
						item.minute = minute
					})
					console.log(this.dataArr)
				})
			},
			// 获取奖品列表
			async getprizess(){
					let res = await this.ApiRequestPostNOMess('api/mall/ebactivity/luck-draw-award-pool/get-list-by-fshopID', {FShopID:this.shopID})
					console.log('奖品列表返回',res)
					if (res.obj == null || res.obj == undefined || res.obj == [] || res.obj.length == 0) {
						this.showImge = true
					} else {
						this.showImge = false
					}
					this.prizes = res.obj[0].fDetaillist
					this.fBeginTime = this.getTime(res.obj[0].fBeginTime)    
					this.fEndTime = this.getTime(res.obj[0].fEndTime)
					this.rules =  res.obj[0].fRuleList  
					this.fAwardPoolID = res.obj[0].fAwardPoolID
					this.getWinningList()
			 },
			 // 每次抽完奖返回的数据
			choujiangback(e){
				
			}
		}
	};
</script>
<style lang="scss" scoped>
	/deep/ .el-footer{
		display: none !important;
		height: 0 !important;
	}
	.page-box{
		
	}
	::-webkit-scrollbar {
		width: 7px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 5px;
		  background-color: rgba(255,255,255,0.6);
	}
	::-webkit-scrollbar-track {
		
	}
	.prisbox{
		  width: 90%;
		  height: 180px;
		  margin: 0 auto;
		  display: flex;
		  justify-content:center;
		  align-items: center;
		  flex-direction: column;
		  margin-top: 50px;
		  margin-bottom: 50px;
		  .pris_item{
			  width: 100%;
			  display: flex;
			  justify-content: center;
			  align-items: center;
			  flex-direction: row;
			  margin-bottom: 1px;
			  .pris_title{
				  width: 36%;
				  background-color: #f0f1db;
				  color: #d24767;
				  text-align: center;
				  padding: 8px 0;
			  }
			  .pris_content{
				  width: 63%;
				  background-color: #fdc6d3;
				  color: #d24767;
				  text-align: center;
				  padding: 8px 0;
			  }
		  }
	}
	.forecastbox {
	  width: 100%;
	  height: 95%;
	  font-size: 14px;
	  overflow: auto;
	  // margin-top: 10px;
	
	  &::-webkit-scrollbar {
	    width: 0.16rem;
	    background-color: transparent;
	  }
	  &::-webkit-scrollbar-track {
	    background-color: transparent;
	  }
	  &::-webkit-scrollbar-thumb {
	    border-radius: 0.08rem;
	    background-color: rgba(255, 255, 255, 0);
	    box-shadow: 0px 1px 3px 0px rgba(44, 47, 49, 0);
	  }
	  /*滚动条的上下两端的按钮*/
	  &::-webkit-scrollbar-button {
	    height: 0px;
	  }
	
	  .seamless-warp {
	    width: 100%;
	    height: 90%;
	  }
	
	  .textbox {
	    letter-spacing: 2px;
	    line-height: 27px;
		font-style: normal;
		font-size: 14px;
	  }
	}
</style>
